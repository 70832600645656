<template>
  <div>
    <div class='mb-2'>
      <label class='flex flex-row justify-start items-center gap-x-1'>
        <input type='checkbox' v-model='author.isPresenter'>
        <span :for='author.isPresenter'>Presenter</span>
      </label>
      <label class='flex flex-row justify-start items-center gap-x-1'>
        <input type='checkbox' v-model='author.isCorrespondant'>
        <span :for='author.isCorrespondant'>Corresponding Author</span>
      </label>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-900'>First & Middle Name</label>
      <input type='text' v-model='author.firstName' class='border py-1 px-2 rounded block w-80'>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-900'>Last Name</label>
      <input type='text' v-model='author.lastName' class='border py-1 px-2 rounded block w-80'>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-900'>Email</label>
      <input type='email' v-model='author.email' class='border py-1 px-2 rounded block w-80'>
      <div v-if='!validEmail' class='mt-2 mb-6 text-red-600 text-xs text-left'>Please check author`s email format.</div>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-900'>Phone Number</label>
      <vue-phone-number-input
        class='block w-80'
        v-model='author.phoneNumber'
        :valid-color='eventMainThemeColor'
        @update='updatePhoneNumberInput' />
    </div>
    <div class='mb-8'>
      <label class='uppercase text-sm text-gray-900 mb-4'>Affiliations</label>
      <div v-if='showingEventId === 32' class='bg-gray-100 p-4 mb-2 text-sm '>
        <ul class='list-disc list-inside'>
          <li class='mb-1'>If you have multiple affiliations, please select them multiple times.</li>
          <li class='mb-1'>If you have accidentally selected the wrong affiliation by clicking multiple times, you can also simply click X to remove it.</li>
          <li class='mb-1'>If you would like to add additional affiliations, please exit this window and add them under "Affiliations."</li>
        </ul>
      </div>
      <select
        v-if='hasSelectableAffiliations'
        class='border py-1 px-2 rounded block w-80'
        v-model='selectedAffiliation'
        @change='selectAffiliation'>
        <option disabled>Select Affiliation</option>
        <option v-for='affiliation in editingAbstractSubmission.affiliations'
          :key='`affiliation-${affiliation.affiliationIndex}`'
          :value='affiliation'>
          {{affiliation.department}}, {{affiliation.affiliation}}, {{affiliation.country}}
        </option>
      </select>
      <div v-else class='p-4 bg-gray-50 rounded text-gray-500 text-sm'>
        Please add affiliations on the previous screen.
      </div>
    </div>
    <table class='w-full text-xs mb-8'>
      <thead>
        <tr class='text-sm text-gray-600'>
          <th class='text-left px-2 py-2'>Affiliation</th>
          <th class='text-left px-2 py-2'>Department</th>
          <th class='text-left px-2 py-2'>Country</th>
        </tr>
      </thead>
      <tbody v-if='author.affiliations.length'>
        <tr v-for='affiliation in author.affiliations'
          :key='`affiliation-${affiliation.department}, ${affiliation.affiliation}, ${affiliation.country}`'
          class='border-t cursor-move hover:bg-gray-100'>
            <td class='text-left px-2 py-2'>{{affiliation.affiliation}}</td>
            <td class='text-left px-2 py-2'>{{affiliation.department}}</td>
            <td class='text-left px-2 py-2'>{{affiliation.country}}</td>
            <td class='text-right pl-2 py-2 flex flex-row justify-end items-center gap-x-1'>
              <button
                @click='removeAffiliation(affiliation)'
                class='hover:bg-gray-200 p-1 rounded-md'>
                <x-icon class='h-4'/>
              </button>
            </td>
          </tr>
      </tbody>
      <tbody v-else>
        <tr class='border-t cursor-move hover:bg-gray-100'>
          <td colspan='4' class='p-4 bg-gray-50 rounded text-gray-500 text-sm'>Please select an affiliation from the list above.</td>
        </tr>
      </tbody>
    </table>
    <button
      class='border py-2 px-8 text-center text-white text-sm uppercase bg-gray-900 block rounded-md w-64'
      :class='needToAddAuthorDataClass'
      :disabled='needToAddAuthorData'
      @click='saveAuthor'>
      Save Author
    </button>
  </div>
</template>

<script>
import { XIcon } from 'vue-tabler-icons'
import { mapState, mapGetters, mapMutations } from 'vuex'
import cloneDeep                  from 'lodash/cloneDeep'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'AbstractSubmissionEditAuthor',
  components: {
    VuePhoneNumberInput,
    XIcon,
  },
  computed: {
    ...mapState('abstracts', [
      'editingAuthor',
      'editingAbstractSubmission'
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventMainThemeColor',
    ]),
    hasSelectableAffiliations () {
      return this.editingAbstractSubmission.affiliations && this.editingAbstractSubmission.affiliations.length > 0
    },
    validEmail () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return this.author.email && mailformat.test(this.author.email)
    },
    needToAddAuthorData () {
      return !this.author.firstName ||
             !this.author.lastName ||
             !this.validEmail ||
             !this.author.phoneNumber ||
             !this.author.affiliations.length
    },
    needToAddAuthorDataClass () {
      return this.needToAddAuthorData ? 'opacity-50 cursor-not-allowed' : ''
    },
  },
  data () {
    return {
      author: {},
      selectedAffiliation: {},
    }
  },
  watch: {
    'editingAuthor': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.author = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapMutations('abstracts', [
      'addNewAuthor',
      'updateAuthor'
    ]),
    updatePhoneNumberInput (data) {
      this.author.phoneNumber = data.formatInternational
    },
    saveAuthor () {
      if (this.editingAuthor.authorIndex === -1) {
        this.addNewAuthor(this.author)
      } else {
        this.updateAuthor(this.author)
      }
      this.$modal.hide('edit-author')
    },
    authorBackground (affiliation) {
      return `${affiliation.department}, ${affiliation.affiliation}, ${affiliation.country}`
    },
    selectAffiliation () {
      let index = this.author.affiliations.findIndex(affiliation => this.authorBackground(affiliation) === this.authorBackground(this.selectedAffiliation))
      if (index > -1) {
        // do nothing
      } else {
        this.author.affiliations.push(this.selectedAffiliation)
      }
    },
    removeAffiliation (removedAffiliation) {
      let index = this.author.affiliations.findIndex(affiliation => this.authorBackground(affiliation) === this.authorBackground(removedAffiliation))
      if (index > -1) {
        this.author.affiliations.splice(index, 1)
      }
    },
  },
}
</script>
